const initial_state = () => ({
  position_type: "1",
  page_positions: [
    {
      page: null,
      xpos: null,
      ypos: null
    }
  ],
  stamped_file: null,
  approved: false,
  paper_size: null,
  document_type: {},
  profile: null
});

export default {
  namespaced: true,
  state: initial_state,
  getters: {},
  mutations: {
    SET_INITIAL_STATE: state => Object.assign(state, initial_state()),
    SET_POSITION_TYPE: (state, payload) => (state.position_type = payload),
    SET_PAGE_POSITIONS: (state, payload) => (state.page_positions = payload),
    SET_STAMPED_FILE: (state, payload) => (state.stamped_file = payload),
    SET_APPROVAL: (state, payload) => (state.approved = payload),
    SET_PAPER_SIZE: (state, payload) => (state.paper_size = payload),
    SET_DOCUMENT_TYPE: (state, payload) => (state.document_type = payload),
    SET_PROFILE: (state, payload) => (state.profile = payload)
  }
};
