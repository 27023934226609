export default {
  date: "1-30-24",
  env_restriction: [],
  content: [
    {
      module: "Features/Fixes",
      updates: [
        "Improve Page loaders, now, initial page load will only show the circle loader and when navigating through tabs it will display the table line loader.",
        "Improved suspend display in all accounts tab, updated icon indicator for suspended and active user for clarity, added Suspend and Unsuspend buttons for clear indication on the user status.",
        "Client notification regarding invoices and receipts via email now comes with attachment, this will allow clients to download copy of invoice faster.",
        "Improve security feature for Client non-admin role relating to add funds, now this role can no longer add funds or view cc details, role elevation to admin is required in order for this functionality to work.",
        "Update name field indicator in Project List Filter, now only showing Name.",
        "Update on tabs under Accounts, now it's clearly showing a highlight for selected and unselected tabs giving this clarity to users."
      ]
    },
    {
      module: "Bugs/Hot Fix",
      updates: [
        "Fixed Error for PZSE access roles where exporting data in the Project List getting Error 504.",
        "Fixed minor issue in PZSE client access where when navigating to the Project List the middle part of the table shows as default instead of the top list.",
        "Fixed issue with Add funds  in the top of the page where when the plus button is clicked it does not show the modal.",
        "Fixed Horizontal scroll issue in Project List where it doesnt take you to the end of the table.",
        "Fixed issue when upgrading project and adding wet stamp where when selecting quantity and updating it doesnt reflect the total cost.",
        "Fixed issue where PZSE clients unable to download Stamped Plan-set for completed Full Structural Project.",
        "Fixed balance display in Transactions showing different values between the Top and Middle balance view",
        "Fixed issue in Trabsaction list - Billing when clicking the edit button, the city field is missing it's value, this is fixed",
        "Fixed issue in add funds modal where after adding fund successfully it does not exit the modal",
        "Fixed issue in Add Funds where when new credit card is added it throws an error",
        "Typo Fixed of Project number fixed",
        "Updated mandatory fields when editing details in Transactions -> Billing, now will show error if required fields are missing when saving.",
        "Fixed issue where PZSE Coordinators are unable to redline and complete a project",
        "Fixed issuu where PZSE Coordinators not receiving New Project Created emails",
        "Fixed issue where PZSE coordinators are receiving invoices in the PZSE mail portal",
        "Fixed issue where PZSE coordinators getting notified on actions they did not immplemet, not subject has clear distinction whether the notification is for Coordinator or Client",
        "Fixed filtering issue in Accounts where no data was showing available"
      ]
    }
  ]
};
