import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    filters: [],
    id: null,
    formdata: {
      name: "",
      filters: {
        is_archived: null,
        with_wet_stamp: null,
        type: [],
        status: [],
        claimed: null,
        engineer_id: null,
        quality_control_id: null,
        account_id: null,
        created_by: null,
        created_at_start_date: null,
        created_at_end_date: null,
        archived_at: null,
        client_po: [],
        archived_at_start_date: null,
        archived_at_end_date: null
      }
    }
  },
  getters: {
    is_filters_has_value: state => {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(state.formdata.filters)) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            return true;
          }
        } else {
          if (value) {
            return true;
          }
        }
      }
      // Enable Filter button - regardless filter has value
      // return false;
      return true;
    }
  },
  mutations: {
    SET_FILTERS: (state, payload) => (state.filters = payload),
    SET_ID: (state, payload) => (state.id = payload),
    SET_FORMDATA: (state, payload) => {
      if (!payload.filters.is_archived) {
        payload.filters.is_archived = null;
        payload.filters.archived_at = null;
      }

      state.formdata = payload;
    },
    SET_TO_FORMDATA_ORIGINAL_FILTER: (state, payload) => {
      const original_filter = _.find(state.filters, { id: payload });

      const copy = _.cloneDeep(original_filter);

      const object = {
        filters: copy.filters,
        name: copy.name
      };

      if (!copy.filters.is_archived) {
        object.filters.is_archived = null;
        object.filters.archived_at = null;
      }

      state.formdata = object;
    }
  },
  actions: {
    getData: async ({ commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get("/project-filter-presets");
        const filters = _.get(response, "data.result", []);

        commit("SET_FILTERS", filters);

        return response;
      } catch (error) {
        throw error;
      }
    },
    saveData: async (context, { id = null, formdata: data }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const method = _.isNil(id) ? "post" : "put";
        const url = _.isNil(id)
          ? "/project-filter-presets"
          : "/project-filter-presets/" + id;

        return await api.request({ method, url, data });
      } catch (error) {
        throw error;
      }
    },
    deleteData: async (context, payload) => {
      // eslint-disable-next-line no-useless-catch
      try {
        return await api.delete("/project-filter-presets/" + payload.id);
      } catch (error) {
        throw error;
      }
    }
  }
};
