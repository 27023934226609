import _ from "lodash";
/* import moment from "moment"; */
import api from "@/api";
import EventBus from "@/eventBus";

export default {
  namespaced: true,
  state: {
    data_ready: false,
    data: [],
    meta: null,
    filters: {
      term: ""
    }
  },
  mutations: {
    SET_FILTERS: (state, payload) => (state.filters = payload),
    SET_DATA_READY: (state, payload) => (state.data_ready = payload),
    SET_DATA: (state, payload) => {
      state.data = payload.data;
      state.meta = payload.meta;
    }
  },
  actions: {
    async index({ state, commit }, page_filters) {
      commit("SET_DATA_READY", false);

      const url = `/stamping-profiles`;
      const params = {
        page: page_filters.page,
        per_page: page_filters.per_page,
        term: state.filters.term
      };
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(url, { params });
        const results = _.get(response, "data.result", []);

        commit("SET_DATA", results);
      } catch (error) {
        throw error;
      }

      commit("SET_DATA_READY", true);
    },

    delete: async (context, id) => {
      // eslint-disable-next-line no-useless-catch
      try {
        await api.delete("/stamping-profiles/" + id);

        EventBus.$emit("reloadStampingProfiles");
      } catch (error) {
        throw error;
      }
    }
  }
};
