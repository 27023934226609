const initial_state = () => ({
  position_type: "3",
  page_positions: [
    {
      page: "*",
      xpos: null,
      ypos: null
    }
  ],
  stamped_file: null,
  approved: false,
  paper_size: {
    name: "17x11 (Tabloid)",
    dimension: "3300x5100",
    scale: 12
  },
  iebc_paper_size: {
    name: "8.5x11 (Letter)",
    dimension: "3450x2700",
    scale: 5
  },
  dynamic_paper_size: {
    name: "Dynamic Size",
    dimension: "3450x2700",
    scale: 7
  },
  document_type: {},
  description: null,
  state: null,
  id: null
});

export default {
  namespaced: true,
  state: initial_state,
  getters: {},
  mutations: {
    SET_INITIAL_STATE: state => Object.assign(state, initial_state()),
    SET_POSITION_TYPE: (state, payload) => (state.position_type = payload),
    SET_PAGE_POSITIONS: (state, payload) => (state.page_positions = payload),
    SET_STAMPED_FILE: (state, payload) => (state.stamped_file = payload),
    SET_APPROVAL: (state, payload) => (state.approved = payload),
    SET_PAPER_SIZE: (state, payload) => (state.paper_size = payload),
    SET_DOCUMENT_TYPE: (state, payload) => (state.document_type = payload),
    SET_DESCRIPTION: (state, payload) => (state.description = payload),
    SET_STAMPING_DATA: (state, payload) => (state = payload),
    SET_DYNAMIC_PAPER_SIZE: (state, payload) =>
      (state.dynamic_paper_size = payload)
  }
};
