export default {
  date: "2-13-24",
  env_restriction: [],
  content: [
    {
      module: "Enhancements",
      updates: [
        "The Quality Control field has been removed from the Project List Filters for a streamlined experience.",
        "Enhanced the checkbox functionality for selected filters, allowing users to deselect filters directly from the dropdown list for improved usability.",
        'Introduced an additional column in the Project List named "Client Project Number" to assist clients in easily identifying their projects.',
        "Restricted the ability of Vendor Admins to create new users.",
        "Updated the Vendor Group Manager view to display users within the manager’s group for better management and oversight."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Updated the AHJ API to include State, County, and City in the payload, aligning the query more closely with the engineers' needs.",
        'Addressed a Portal Notification issue where the "load more" button failed to display additional notifications and corrected the "No Item to show" error when attempting to load more notifications.',
        'Resolved a problem with the "Upgrade Details" function, ensuring it now accurately displays the details of the upgrade.',
        "Corrected a flaw in the Update Details modal, where changing the Pitch Type and clicking outside the modal would save the new value without the need to press the update button.",
        "Fixed an issue where clicking on the uploaded list in Project Files did not display a list of completed uploads.",
        "Restore the Group name Column in Accounts page, it should now be visible PZSE2-4912 - Fixed an issue in Comments tab where replies does not show in the thread."
      ]
    }
  ]
};
