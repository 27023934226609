import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    filters: {
      term: "",
      is_archived: null,
      with_wet_stamp: null,
      type: null,
      status: null,
      claimed: null,
      engineer_id: null,
      quality_control_id: null,
      account_id: null,
      created_by: null,
      created_at: null,
      archived_at: null
    },
    projectMeta: {},
    projectData: []
  },
  getters: {},
  mutations: {
    SET_FILTERS: (state, payload) => {
      for (const key in payload) {
        state.filters[key] = payload[key];
      }
    },
    SET_PROJECT_CURR_PAGE: (state, data) =>
      (state.projectMeta.current_page = data),
    SET_PROJECT_DATA: (state, payload) => (state.projectData = payload)
  },
  actions: {
    getData: async ({ state, commit }, pageNumber) => {
      const params = { ...state.filters };

      const project = await api.get(`/projects/archived?page=${pageNumber}`, {
        params
      });
      let projectData = _.get(project, "data.result", []);

      commit("SET_PROJECT_DATA", projectData);
      return project;
    }
  }
};
