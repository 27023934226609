const initial_state = () => ({
  stamped_file: null,
  approved: false,
  dynamic_paper_size: {
    name: "Dynamic Size",
    dimension: "3450x2700",
    scale: 7
  },
  id: null,
  state: null,
  page_positions: {
    xpos: null,
    ypos: null
  }
});

export default {
  namespaced: true,
  state: initial_state,
  getters: {},
  mutations: {
    SET_INITIAL_STATE: state => Object.assign(state, initial_state()),
    SET_STAMPED_FILE: (state, payload) => (state.stamped_file = payload),
    SET_APPROVAL: (state, payload) => (state.approved = payload),
    SET_STAMPING_DATA: (state, payload) => (state = payload),
    SET_DYNAMIC_PAPER_SIZE: (state, payload) =>
      (state.dynamic_paper_size = payload),
    SET_STATE: (state, payload) => (state.state = payload),
    SET_PAGE_POSITIONS: (state, payload) => (state.page_positions = payload)
  }
};
