import _ from "lodash";
import cookies from "js-cookie";
import api from "@/api";

const initialState = () => ({
  auth_token: {},
  user: {},
  permissions: {},
  keep_me_logged_in: false
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    is_authenticated: state => !_.isEmpty(state.auth_token),
    user_id: state => _.get(state, "user.id", null),
    full_name: state => _.get(state, "user.full_name", null),
    email: state => _.get(state, "user.email", null),
    user_role: state => _.get(state, "user.role.formatted", null),
    user_avatar: state => _.get(state, "user.avatar", null),
    user_initials: state => _.get(state, "user.full_name_initials", null),
    is_client: state => _.get(state, "user.account.is_client", true),
    company_name: state => _.get(state, "user.account.company_name", null),
    is_engineer: state => _.get(state, "user.role.value") === "pzse-engineer",
    is_pzse_admin: state => _.get(state, "user.role.value") === "pzse-admin",
    is_vendor: state => _.get(state, "user.role.value").indexOf("vendor") >= 0,
    is_coordinator: state =>
      _.get(state, "user.role.value") === "pzse-coordinator",
    is_client_admin: state =>
      _.get(state, "user.role.value") === "client-admin",
    is_user_pzse: state => _.get(state, "user.role.value").indexOf("pzse") >= 0,
    is_super_admin: state =>
      _.get(state, "user.role.value").indexOf("super-admin") >= 0,
    account_id: state => _.get(state, "user.account_id", null),
    custom_pricings: state => _.get(state, "user.custom_pricings", null),
    account_setting: state => _.get(state, "user.account_setting", null)
  },
  mutations: {
    SET_KEEP_ME_LOGGED_IN: (state, payload) =>
      (state.keep_me_logged_in = payload),
    SET_AUTH_TOKEN: (state, auth_token) => {
      if (!auth_token) {
        return;
      }

      let expires = 1;
      if (state.keep_me_logged_in) {
        expires = 7;
      }

      if (!cookies.get("auth_token")) {
        cookies.set("auth_token", JSON.stringify(auth_token), {
          expires: expires
        });
      }

      state.auth_token = auth_token;
    },
    INVALIDATE_AUTH_TOKEN: state => {
      // cookies beyond session should not get deleted
      // when invalidating token or logging out
      const cookies_beyond_session = [
        "patch_noshow",
        "patch_count",
        "tour_dontshow"
      ];

      _.each(cookies.get(), (item, index) => {
        const persists = cookies_beyond_session.some(cookie => {
          return index.indexOf(cookie) > -1;
        });

        if (persists) return;

        cookies.remove(index);
      });

      _.each(initialState(), (item, index) => (state[index] = item));
    },
    SET_USER: (state, payload) => {
      cookies.set("user", JSON.stringify(payload));

      state.user = payload;
    },
    SET_PERMISSIONS: (state, payload) => {
      cookies.set("permissions", JSON.stringify(payload));

      state.permissions = payload;
    }
  },
  actions: {
    login: async ({ commit }, payload) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const authentication_response = await api.post("/auth/login", payload);
        const { access_token, expires_in, token_type, refresh_token } = _.get(
          authentication_response,
          "data.result"
        );

        commit("SET_AUTH_TOKEN", {
          access_token,
          expires_in,
          token_type,
          refresh_token
        });

        const current_user_response = await api.get("/users/me");
        const user = _.get(current_user_response, "data.result");
        const permissions = _.get(current_user_response, "data.permissions");

        commit("SET_USER", user);

        commit("SET_PERMISSIONS", permissions);

        return _.concat(authentication_response, current_user_response);
      } catch (error) {
        throw error;
      }
    },
    currentUser: async ({ commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const current_user_response = await api.get("/users/me");
        const user = _.get(current_user_response, "data.result");
        const permissions = _.get(current_user_response, "data.permissions");

        commit("SET_USER", user);

        commit("SET_PERMISSIONS", permissions);

        return current_user_response;
      } catch (error) {
        throw error;
      }
    },
    logout: async ({ commit }) => {
      try {
        return await api.post("/auth/logout");
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        throw error;
      } finally {
        commit("INVALIDATE_AUTH_TOKEN");
      }
    }
  }
};
