import _ from "lodash";
import api from "@/api";
import projectFilterPresets from "./projectFilterPresets";

const initialState = () => ({
  filters: {
    term: "",
    is_archived: null,
    show_archived: false,
    with_wet_stamp: null,
    type: null,
    client_po: null,
    status: null,
    claimed: null,
    engineer_id: null,
    quality_control_id: null,
    account_id: null,
    created_by: null,
    created_at: null,
    created_at_start_date: null,
    created_at_end_date: null,
    archived_at: null,
    archived_at_start_date: null,
    archived_at_end_date: null
  },
  projects_meta: {},
  projects_data: [],
  sort: {
    by: "created_at",
    desc: true
  }
});

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    SET_INITIAL_STATE: state => Object.assign(state, initialState()),
    SET_FILTERS: (state, payload) => {
      for (const key in payload) {
        state.filters[key] = payload[key];
      }
    },
    SET_PROJECT_DATA: (state, payload) => {
      state.projects_data = payload.data;
      state.projects_meta = payload.meta;
    },
    SET_SORTER: (state, payload) => (state.sort = payload)
  },
  actions: {
    getData: async ({ state, commit }, page) => {
      const params = {
        ...state.filters,
        page
      };

      const project = await api.get(`/projects`, { params });

      let data = _.get(project, "data.result", []);
      commit("SET_PROJECT_DATA", data);
    }
  },
  modules: {
    projectFilterPresets
  }
};
