export default {
  date: "2-29-24",
  env_restriction: [],
  content: [
    {
      module: "Features",
      updates: [
        "Added 'Select All/None' options to the Customer Project Number field in the Project List filter for enhanced user navigation and selection efficiency.",
        "Removed 'Quality Control' from the project logs display to streamline project overview clarity.",
        "Implemented visual indicators on filtered columns in the Project List Page to clearly signify active filters for improved user awareness and navigation."
      ]
    },
    {
      module: "Bugs/Hot Fixes",
      updates: [
        "Fixed the 'Date Created' field on invoices to ensure accuracy in reflecting the correct creation date for each transaction.",
        "Improved the formatting and validation of the phone number field in user profiles; it now displays an indicator for incorrect phone number formats.",
        "Resolved an issue in Shipping Profiles where users were incorrectly offered the option to delete profiles that had already been removed."
      ]
    }
  ]
};
